<template>
  <!-- Navigation-->
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
      <div class="container">
          <a class="iv-header text-nowrap" href="#page-top">FRC 7770 - Infinite Voltage</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
              <i class="fas fa-bars ms-1"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
              <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                  <li class="nav-item"><a class="nav-link" href="#stem">STEM</a></li>
                  <li class="nav-item"><a class="nav-link" href="#seasons">SEASONS</a></li>
                  <li class="nav-item"><a class="nav-link" href="#portfolio">GALLERY</a></li>
                  <li class="nav-item"><a class="nav-link" href="#support">SPONSORS</a></li>
                  <li class="nav-item"><a class="nav-link" href="#contact">CONTACT</a></li>
                  <li class="nav-item"><a class="nav-link" href="https://churchvillerec.playbookapi.com/programs/register/" target="_register">JOIN</a></li>
                  <li class="nav-item"><a class="nav-link" href="https://www.firstinspires.org/robotics/frc" target="_first"><img id="first" src="@/assets/img/FIRST_HorzRGB_reverse.png"></a></li>
                  <li class="nav-item">
                    <form action="https://www.paypal.com/donate" method="post" target="_donate">
                      <input type="hidden" name="hosted_button_id" value="NR8SF5LHQW45E" />
                      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="Select First Robotics" alt="Donate with PayPal button" />
                      <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                  </li>
              </ul>
          </div>
      </div>
  </nav>
</template>

<script>
export default {
  name: 'TopNavigation',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.iv-header {
  color: #E6D81C;
  font-size: 25px;
  font-family: pacifica;
  font-weight: bold;
  text-decoration: none;
}
@font-face {
    font-family: 'pacifica';
    src: url('@/css/pacificacondensedregular.ttf');
}
#mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: #E6D81C;
}
#mainNav .navbar-nav .nav-item .nav-link {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.2rem;
    color: #fff;
    letter-spacing: 0.0625em;
}
#first {
  height: 30px;
}
</style>
