<template>
    <!-- GALLERY-->
    <section class="page-section bg-secondary" id="portfolio">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase text-white">GALLERY</h2>
                <!--<h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>-->
            </div>
            <div class="row">
                <div
                v-for="album in albums" 
                v-bind:key="album"
                class="col-lg-4 col-sm-6 mb-4">
                    <div class="portfolio-item">
                        <a class="portfolio-link" data-bs-toggle="modal" :href="`#portfolioModal${album.id}`">
                            <div class="portfolio-hover">
                                <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img class="img-fluid" :src="album.image" alt="..." />
                        </a>
                        <div class="portfolio-caption">
                            <div class="portfolio-caption-heading">{{ album.heading }}</div>
                            <div class="portfolio-caption-subheading text-muted">{{ album.subheading }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <!-- Portfolio Modals-->    
    <div
        v-for="album in albums" 
        v-bind:key="album">
        <div class="portfolio-modal modal fade" :id="`portfolioModal${album.id}`" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="@/assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="">
                                <div class="modal-body">
                                    <h2 class="text-uppercase">{{ album.title }}</h2>
                                    <p class="item-intro text-muted">{{ album.intro }}</p>
                                    <!-- Filled by javascript buildAlbums-->
                                    <GalleryModal :folder="album.id" />
                                    <div :id="`portfolioModal${album.id}`"></div>
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Close Gallery
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import GalleryModal from '@/components/GalleryModal.vue'

export default {
    name: 'GallerySection',
    components: {
        GalleryModal
    },
    data() {
        return {
            albums: [

                {
                    id: '2024-Competition',
                    heading: '2024',
                    subheading: '2024 Competition Season',
                    image: require('@/assets/gallery/2024-Competition/cover.jpg'),
                    title: "2024 Competition Season",
                    intro: "March 2024"
                },

                {
                    id: '2024-Build',
                    heading: '2024',
                    subheading: '2024 Build Season',
                    image: require('@/assets/gallery/2024-Build/cover.jpg'),
                    title: "2024 Build Season",
                    intro: "Began January 6, 2024"
                },

                {
                    id: '2023-Alexandria',
                    heading: '2023',
                    subheading: 'Alexandria - Week 3',
                    image: require('@/assets/gallery/2023-Alexandria/cover.jpg'),
                    title: "CHS District Alexandria VA Event",
                    intro: "March 17 to March 19, 2023 - Hayfield Secondary School"
                },
                {
                    id: '2022-BoB',
                    heading: '2022',
                    subheading: 'Battle of Baltimore',
                    image: require('@/assets/gallery/2022-BoB/cover.jpg'),
                    title: "2022 Battle of Baltimore",
                    intro: "October 22, 2022 - Dulaney High School"
                },
                {
                    id: '2022-Offseason',
                    heading: '2022',
                    subheading: 'Offseason',
                    image: require("@/assets/gallery/2022-Offseason/cover.jpg"),
                    title: "2022 Offseason",
                    intro: "Science Fair, Fund Raising, Techbrick"
                },
                {
                    id: '2022-District',
                    heading: '2022',
                    subheading: 'Districts',
                    image: require("@/assets/gallery/2022-District/IMG_8755.jpg"),
                    title: "2022 FIRST Chesapeake District Championship",
                    intro: "April 7-9, 2022 at the Hampton Coliseum, Hampton, Virginia"
                },
                {
                    id: '2022-Qualifiers',
                    heading: '2022',
                    subheading: 'Qualifiers',
                    image: require("@/assets/gallery/2022-Qualifiers/IMG_8236x.jpg"),
                    title: "2022 FIRST Chesapeake District Qualifiers",
                    intro: "March 12, 2022 and March 20, 2022"
                },
                {
                    id: '2022-Build',
                    heading: '2022',
                    subheading: 'Build Season',
                    image: require("@/assets/gallery/2022-Build/1D0B2262-328E-411A-881C-5E2BC9C6CC00.jpg"),
                    title: "2022 Build",
                    intro: "Began January 8, 2022"
                },
                {
                    id: '2019',
                    heading: '2019',
                    subheading: 'Deep Space',
                    image: require("@/assets/gallery/2019/2019-09x.jpg"),
                    title: "2019 Season",
                    intro: ""
                }
            ]
        }
    }
}
</script>

<style>
#portfolio .portfolio-item .portfolio-link .portfolio-hover {
    background: rgba(230, 216, 28, 0.9);
}
</style>