<template>    
        <!-- STEM-->
        <section class="page-section bg-light" id="stem">
            <div class="container" id="stem-text">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">NO EXPERIENCE REQUIRED</h2>
                    <h2 class="section-subheading">What can you do and what can you gain?</h2>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <h4 >SKILLS</h4>
                        <div class="section-subheading">
                            <ul>
                                <li>build mechanical parts of the bot</li>
                                <li>programming</li>
                                <li>electrical work for the bot</li>
                                <li>social media management</li>
                                <li>video editing</li>
                                <li>fundraising</li>
                                <li>recruitment</li>
                                <li>finances</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h4 >Learn...</h4>
                        <div class="section-subheading">
                            <ul>
                                <li>how to safely use power tools</li>
                                <li>how to work as a team</li>
                                <li>leadership skills</li>
                                <li>soldering</li>
                                <li>fabrication and machining</li>
                                <li>how to code</li>
                                <li>how to run a social media page</li>
                                <li>how to do student outreach</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
  name: 'StemSection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section#stem {
  background-image: url("@/assets/img/soldering50.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  color:black;
}
.section-subheading {
    font-size: larger;
    font-weight: bolder;
}
</style>