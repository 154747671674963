<template>    
    <!-- Footer-->
    <footer class="footer py-4 iv-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">Copyright &copy; Infinite Voltage 2024</div>
                <div class="col-lg-5">
                </div>
                <div class="col-lg-3" id="social">
                    <table width="100%"><tr>
                    <td><a class="nav-link" href="https://www.instagram.com/infinite.voltage" target="_social"><img style="height: 35px;" src="@/assets/img/instagram.png" /></a></td>
                    <td><a class="nav-link" href="https://twitter.com/7770_Voltage" target="_social"><img style="height: 35px;" src="@/assets/img/twitter.png" /></a></td>
                    <td><a class="nav-link" href="https://www.facebook.com/infinte.voltage.1" target="_social"><img style="height: 35px;" src="@/assets/img/facebook.png" /></a></td>
                    <td><a class="nav-link" href="https://www.youtube.com/channel/UCFCmrO1fpU9TuSjnrd2OnLw" target="_social"><img style="height: 35px;" src="@/assets/img/youtube.png" /></a></td>
                    <td><a class="nav-link" href="https://www.thebluealliance.com/team/7770" target="_social"><img style="height: 35px;" src="@/assets/img/blue-alliance.png" /></a></td>
                    <td><form action="https://www.paypal.com/donate" method="post" target="_donate">
                            <input type="hidden" name="hosted_button_id" value="NR8SF5LHQW45E" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="Select First Robotics" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </td>
                    </tr></table>
                </div>
                <!--<div class="col-lg-4 text-lg-end">
                    <a class="text-decoration-none me-3" href="#!">Privacy Policy</a>
                    <a class="text-decoration-none" href="#!">Terms of Use</a>
                </div>-->
            </div>
        </div>
    </footer>
</template>
<style scoped>
.iv-footer {
  color: #E6D81C;
  font-weight: bold;
  text-decoration: none;
  background-color: black;
}
social{
    align-items: flex-end;
}
</style>