<template>
    <!-- Contact-->
    <section class="page-section cloud-bg" id="contact">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">Contact Us</h2>
            </div>
            <form id="contactForm"  @submit.prevent="submitForm">
                <div class="row align-items-stretch mb-5">
                    <div class="col-md-6">
                        <div class="form-group">
                            <!-- Name input-->
                            <input class="form-control" v-model="name" type="text" placeholder="Your Name *"
                                data-sb-validations="required" />
                            <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                        </div>
                        <div class="form-group">
                            <!-- Email address input-->
                            <input class="form-control" v-model="email" type="email" placeholder="Your Email *"
                                data-sb-validations="required,email" />
                            <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                            <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                        </div>
                        <div class="form-group mb-md-0">
                            <!-- Phone number input-->
                            <input class="form-control" v-model="phone" type="tel" placeholder="Your Phone *"
                                data-sb-validations="required" />
                            <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-textarea mb-md-0">
                            <!-- Message input-->
                            <textarea class="form-control" v-model="message" placeholder="Your Message *"
                                data-sb-validations="required"></textarea>
                            <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                        </div>
                    </div>
                </div>
                <!-- Submit success message-->
                <!---->
                <!-- This is what your users will see when the form-->
                <!-- has successfully submitted-->
                <div class="d-none" id="submitSuccessMessage">
                    <div class="text-center text-white mb-3">
                        <div class="fw-bolder">Form submission successful!</div>
                    </div>
                </div>
                <!-- Submit error message-->
                <!---->
                <!-- This is what your users will see when there is-->
                <!-- an error submitting the form-->
                <div class="d-none" id="submitErrorMessage">
                    <div class="text-center text-danger mb-3">Error sending message!</div>
                </div>
                <!-- Submit Button-->
                <div class="text-center"><button class="btn btn-primary btn-xl text-uppercase" id="submitButton"
                        type="submit">Send Message</button></div>
            </form>
        </div>
    </section>
</template>

<script>
// firebase methods
import {ref,set} from 'firebase/database'

export default {
    data() {
        // default values
        return {
            name: '',
            email: '',
            phone: '',
            message: ''
        };
    },
    methods: {
        submitForm() {
            //set(ref(this.$db, 'contacts/' + Math.random().toString(36).slice(2, 7)), {
            const ts = new Date();
            set(ref(this.$db, 'contacts/' + this.removeSpecialCharacters(ts.toJSON())), {
                name: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message
            })
                .then(() => {
                    console.log('Message sent!')
                    // ping discord
                    var msg = "Name: " + this.name + "\nEmail: " + this.email + "\nPhone: " + this.phone + "\nMessage: " + this.message;
                    this.sendDiscordMessage(msg)
                    // clean up
                    this.name = ''
                    this.email = ''
                    this.phone = ''
                    this.message = ''
                    // show success
                    document.getElementById("submitSuccessMessage").className = "d-block";
                    document.getElementById('contactForm').reset();
                })
                .catch(error => {
                    console.error('Error sending message:', error)
                })
        },
        sendDiscordMessage(msg) {
            const request = new XMLHttpRequest();
            request.open("POST", "https://discordapp.com/api/webhooks/" + process.env.VUE_APP_DISCORD_WEBHOOK_ID + "/" + process.env.VUE_APP_DISCORD_WEBHOOK_TOKEN);
            request.setRequestHeader('Content-type', 'application/json');

            const params = {
                username: "Infinite Voltage Web Form",
                avatar_url: "",
                content: msg
            }

            request.send(JSON.stringify(params));
        },
        removeSpecialCharacters(inputString) {
            // Regular expression pattern to match special characters
            var pattern = /[^\w\s]/gi;

            // Remove special characters using the pattern
            var cleanedString = inputString.replace(pattern, '');

            return cleanedString;
        }
    }
}

</script>


<style >section#contact {
    background-image: url("@/assets/img/clouds.jpg");
    background-repeat: no-repeat;
    background-size: auto;
}</style>