<template>
    <div class="row" id="gallery">
        <div 
            v-for="image in getImageList()" 
            v-bind:key="image"
            class="col-6 col-lg-6">
            <figure class="figure">
                <img class="w-100 mb-0" loading="lazy" :src="getImage(image.file)" />
                <figcaption class="figure-caption">{{ image.caption }}</figcaption>
            </figure>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GalleryModal',
    props: {
        folder: String
    },
    methods: {
        getImageList: function() {
            // load the table of contents
            var toc = require('@/assets/gallery/'+this.folder+'/toc.json');
            return toc.images;
        },
        getImage: function(fileName) {
            // load the file
            return require('@/assets/gallery/'+this.folder+'/medium/' + fileName);
        }
    }
}
</script>