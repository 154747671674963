<template>
      <TopNavigation msg="Welcome to Your Vue.js App"/>
      <MastheadSection />
      <StemSection />
      <ExpectationsSection />
      <SeasonsSection />
      <GallerySection />
      <SponsorSection />
      <ContactForm />
      <FooterBlock />
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import MastheadSection from '@/components/MastheadSection.vue'
import StemSection from '@/components/StemSection.vue'
import ExpectationsSection from '@/components/ExpectationsSection.vue'
import SeasonsSection from '@/components/SeasonsSection.vue'
import GallerySection from '@/components/GallerySection.vue'
import SponsorSection from '@/components/SponsorSection.vue'
import ContactForm from '@/components/ContactForm.vue'
import FooterBlock from '@/components/FooterBlock.vue'

export default {
  name: 'App',
  components: {
    TopNavigation,
    MastheadSection,
    StemSection,
    ExpectationsSection,
    SeasonsSection,
    GallerySection,
    SponsorSection,
    ContactForm,
    FooterBlock
  }
}
</script>

<style>
.page-section {
  padding-top: 2rem;
}
</style>
