<template>
    <header class="masthead">
        <div class="container">
            <div class="iv-title-2">TEAM 7770</div>
            <img height="200" src="@/assets/img/infinite-voltage.jpg" />
            <div class="iv-title-1">INFINITE VOLTAGE</div>
            
            <div class="row text-center">
                <div class="col-md-4">
                    <h4 >Our Mission</h4>
                    <div class="section-subheading">To inspire young people to become innovators and tomorrow's leaders through active participation in dynamic problem-solving and robotics competition.</div>
                </div>
                <div class="col-md-4">
                    <h4 >Our Team</h4>
                    <div class="section-subheading">Infinite Voltage (Team 7770) is a FIRST Robotics competition team located in the Bel Air, Maryland area. We are composed of high school students and mentors that work together to create a functioning robot while learning team building skills. Through this experience, students have the opportunity to broaden their understandings of technology and science in an encouraging work environment.</div>
                </div>
                <div class="col-md-4">
                    <h4 >Our Vision</h4>
                    <div class="section-subheading">To transform our culture to have a greater appreciation and knowledge of science and technology. To equip alumni with useful skills needed in the future.</div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: 'MastheadSection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>.iv-title-1 {
    color: #E6D81C;
    font-size: 100px;
    font-family: pacifica;
    font-weight: bold;
    text-shadow: 2px 2px 4px #fff;
    background-color: #000;
  }
  
  .iv-title-2 {
    color: #E6D81C;
    font-size: 50px;
    font-family: pacifica;
    font-weight: bold;
    text-shadow: 2px 2px 4px #fff;
    background-color: #000;
  }
header.masthead {
  background-image: none;
  background-color: #000;
  padding-top: 6.5rem;
  padding-bottom: 2rem;
}
</style>