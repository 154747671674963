<template>
    <section class="page-section bg-light" id="support">
        <div class="py-5">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Sponsors</h2>
                    <h3>You can support us too with PayPal.  Click Donate and select "First Robotics".</h3>
                        <form action="https://www.paypal.com/donate" method="post" target="_donate">
                            <input type="hidden" name="hosted_button_id" value="NR8SF5LHQW45E" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="Select First Robotics" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                </div>
                <div class="row align-items-center">
                    <div
                        v-for="sponsor in sponsors" 
                        v-bind:key="sponsor"
                        class="col-sm-6">
                        <a href="#!"><img class="img-fluid max-width: 100%; height: auto;" :src="sponsor.logo" alt="..." :aria-label="sponsor.name" /></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>

</template>

<script setup>
import {ref} from 'vue'
const sponsors = ref([    
    /*{
        logo: require('@/assets/season/'),
        name: ''
    },*/
    {
        logo: require('@/assets/sponsor/Boeing_full_logo.svg'),
        name: 'Boeing'
    },
    {
        logo: require('@/assets/sponsor/Raytheon_Technologies_logo.svg'),
        name: 'Raytheon'
    },
    {
        logo: require('@/assets/sponsor/dcslogo_horiz.svg'),
        name: 'DCS'
    },
    {
        logo: require('@/assets/sponsor/Northrop_Grumman_logo_blue-on-clear_2020.svg'),
        name: 'Northrop Grumman'
    },
    {
        logo: require('@/assets/sponsor/DoDSTEM/Full_Color_DoDSTEM_Logo.svg'),
        name: 'DoDSTEM'
    },
    {
        logo: require('@/assets/sponsor/Lockheed_Martin_logo.svg'),
        name: 'Lockheed Martin'
    },
    {
        logo: require('@/assets/sponsor/minuteman-press-logo-vector.svg'),
        name: 'Minuteman Press'
    }
])
</script>