<template>    
        <!-- EXPECTATIONS -->
        <section class="page-section bg-lightsecondary" id="stem">
            <div class="container" id="stem-text">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">WHAT TO EXPECT</h2>
                    <h2 class="section-subheading">What to expect from the Infinite Voltage robotics program</h2>
                </div>
                
                <div id="stem-calendar">
                    <table class="table">
                        <thead>
                            <tr class="table-dark">
                            <th scope="col">Term</th>
                            <th scope="col">Months</th>
                            <th scope="col">Primary Focus</th>
                            <th scope="col">Time Commitment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-info">
                            <th scope="row">Fall</th>
                            <td>Sep - Dec</td>
                            <td>Recruiting. Review skills (programing, engineering), Offseason scrimmage</td>
                            <td>1 meeting / week + occasional outreach</td>
                            </tr>
                            <tr class="table-warning">
                            <th scope="row">FRC Build Season</th>
                            <td>Jan - Feb</td>
                            <td>Design & construction of FRC robots</td>
                            <td>2-3 meetings / week + occasional outreach</td>
                            </tr>
                            <tr class="table-danger">
                            <th scope="row">FRC Competitions</th>
                            <td>Mar - April</td>
                            <td>Finalize designs, competitions, redesigns as necessary</td>
                            <td>2-3 meetings / week + 3-4 all-day competition</td>
                            </tr>
                            <tr class="table-success">
                            <th scope="row">Offseason</th>
                            <td>May - Aug</td>
                            <td>Outreach, Summer programs, Fundraising</td>
                            <td>2-3 meetings / month</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container p-2">
                <div class="card bg-light">
                    <div class="card-header">Overview</div>
                    <div class="card-text p-2">Students meet 1 evening or Saturday per week during the “off-season” periods. This is a time for learning, 
                        team building, preparation, community outreach, and fundraising. During the FIRST Robotics 
                        season, meetings increase to three a week, and often include additional work days near competition dates.</div>
                    <div class="card-text p-2">During the FRC season, students have the opportunity to participate at least two large scale 
                        competitions. More (e.g. States, exhibitions, Worlds) may be possible depending on funding and 
                        team performance. These are all-day events held on weekends during March and April.</div>
                </div>
            </div>
            <div class="container p-2">
                <div class="card bg-light">
                    <div class="card-header">Student commitment</div>
                    <div class="card-text p-2">Students are expected to attend as much as possible. While there is no explicit attendance policy, 
                        all work is based on team goals. With a relatively small team, each member is a critical component. This 
                        is particularly important during the end of Build and Competition seasons. The pace is much more 
                        leisurely the remainder of the year. Work often can be done at home and may not always require 
                        physically meeting every week.</div>
                    <div class="card-text p-2">
                        A major component of the program is community outreach & reach-back to sponsors. As such, there is an 
                        expectation to participate in additional events which may include evenings or weekends. There is a 
                        direct correlation between the time invested by a student, and how much they get out of the program. 
                        Students learn as much by teaching and talking as they do building and designing.</div>
                </div>
            </div>
            <div class="container p-2">
                <div class="card bg-light">
                    <div class="card-header">Parent commitment</div>
                    <div class="card-text p-2">This is a large program that relies on adult volunteers. While the students run the show, there 
                        are many roles needed to be filled in order to provide the resources necessary for students to 
                        succeed. These range from coaching to mentoring, to fundraising, or arranging logistics. Parents 
                        do NOT necessarily have to attend meetings; however, every participating student is required to 
                        also have a corresponding adult volunteer filling some role. Please note this does not have to be 
                        their parent; it can be a friend or a relative. Some roles are constant, while others are only 
                        occasional.
                    </div>
                </div>
            </div>
            <div class="container p-2">
                <div class="card bg-light">
                    <div class="card-header">FAQ</div>
                    <div class="card-text p-2">
                        <table class="table table-striped">
                            <tr><td>What does it cost?</td></tr>
                            <tr><td>$265 for the year beginning Sept 1; Note: Scholarships are available if 
                                needed. The program is mostly covered by external sponsorships and fundraising.</td></tr>
                            <tr><td>What age groups can join the program?</td></tr>
                            <tr><td>As a FIRST Robotics Competition (FRC) team, Infinite Voltage competes in the large robotics
                                program and focusses mainly on high school level skills.  For younger students, we partner with
                                 <a href="https://www.techbrickprograms.com/home" target="_bricks">Techbrick Education</a> and the kids 
                                 start their robotics journey in the FIRST Lego League (FLL) and FIRST Tech Challenge (FTC) programs.
                            </td></tr>
                            <tr><td>Is this a full-time activity?</td></tr>
                            <tr><td>YES! Consider this to be on par with marching band, Boy Scouts, sports teams, etc</td></tr>
                            <tr><td>OMG, as a parent volunteer, I don’t know anything about robots! And I don’t 
                                have a ton of free time! What could I possibly have to contribute?</td></tr>
                            <tr><td>Don’t worry – most of us have NO background in robotics or engineering either! 
                                The kids will figure it out! Volunteers are needed for things like arranging outreach 
                                events, or fundraising (a big one!), driving, arranging food and water (teenagers eat 
                                a lot!!), or helping find a new home for the program. A lot of parent jobs only take 
                                time at sporadic occasions that you can do at home.</td></tr>
                            <tr><td>Do I have to attend all the meetings?</td></tr>
                            <tr><td>NO! Only coaches and mentors need to, and even they can miss them occasionally. 
                                Many parents do not stay, and do their part from home or elsewhere.</td></tr>
                            <tr><td>How do I join? Where do I get more info?</td></tr>
                            <tr><td>Fill in the form below.  Our mentors will contact you and invite your family to our 
                                next meeting.  We prefer you join us for a few meetings before 
                                registering, to ensure this is the right program for the student.  When you are ready to 
                                join use the "JOIN" link above to register with the Robotics Program at Churchville Rec.</td></tr>
                            <tr><td>Where do you meet?</td></tr>
                            <tr><td>We currently meet in a private shop in Bel Air, MD.  For some of our activities we will join 
                                the Techbrick program at their shop on Aberdeen Proving Ground (APG North) or the Discovery Center in Belcamp, MD.
                                Mentor and parent meetings are held at the Churchville Rec Center.</td></tr>
                            <tr><td>Will you come do a demonstration at my STEM event?</td></tr>
                            <tr><td>YES! STEM outreach is a core component of our program. Contact us through the form below.</td></tr>
                        </table>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
  name: 'StemSection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-subheading {
    font-size: larger;
    font-weight: bolder;
}
</style>
